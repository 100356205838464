<template>
    <div class="card card-body shadow-sm infomations-list">
      <validation-observer
        ref="form_create_entry"
        v-slot="{ passes, valid, validated }"
      >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('restaurant.list_title')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.KYY_TABLE_ORDER_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row">
            <div class="col-xl-3 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.name.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-input :name="filters.name.name" input-style="normal"
                             v-model="filters.name.value"
                  />
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.restaurant.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-select
                    :name="filters.restaurant.name"
                    :options-data="restaurantList"
                    v-model="filters.restaurant.value"
                  />
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.date.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-date-range-picker
                    :name="filters.date.name"
                    v-model="filters.date.value"
                    timePicker24Hour="false"
                    format="YYYY-MM-DD"
                    @input="filters.date.value = $event"
                  />
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.status.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-select :name="filters.status.name"
                    input-style="normal"
                    v-model="filters.status.value"
                    :options-data="TABLE_ORDER_CONSTANT.STATUS_ORDER"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-status="props">
          <td class="app-align-middle text-center">
            {{ getLabelFromOptionArray(props.row.status, TABLE_ORDER_CONSTANT.STATUS_ORDER) }}
          </td>
        </template>

        

        <template v-slot:table-menu-right>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("common_vn.create") }}
          </button>
        </template>
  
        <template v-slot:body-cell-edit="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              @click="handleBtnUpdateClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("common_vn.detail") }}
            </button>
          </td>
        </template>
  
      </app-basic-table>
      </validation-observer>
    </div>
  </template>
  
  <script>
    import AppBasicTable from "@components/_common/list/AppBasicTable";
    import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
    import {ENDPOINT} from "../../../constants/api";
    import {TABLE_ORDER_CONSTANT} from "@constants/kyy_table_order";
    import { getLabelFromOptionArray } from "../../../utils";

    export default {
      name: "CategoryList",
      components: {
        "app-basic-table": AppBasicTable,
        AppDateRangePicker,
      },
      data() {
        return {
          idCache: null,
          paramGetList: {},
          filters: {
            name: {
              name: "name",
              condition: "like",
              label: this.$t("table_order.customer_name")
            },
            restaurant: {
              name: "restaurant_id",
              condition: "equal",
              label: this.$t("restaurant.name")
            },
            date: {
              name: "date",
              condition: "equalDate",
              label: this.$t("table_order.order_time")
            },
            status: {
              name: "status",
              condition: "equal",
              label: this.$t("table_order.status")
            },
          },
          columns: [
            {name: "name", label: this.$t("table_order.customer_name"), sortable: true, textAlign: 'text-center'},
            {name: "restaurant_name", label: this.$t("table_order.restaurant"), sortable: true, textAlign: 'text-center'},
            {name: "date", label: this.$t("table_order.order_time"), sortable: true, textAlign: 'text-center'},
            {name: "guest_number", label: this.$t("table_order.guest_number"), sortable: true, textAlign: 'text-center'},
            {name: "phone_number", label: this.$t("table_order.phone_number"), sortable: true, textAlign: 'text-center'},
            {name: "status", label: this.$t("table_order.status"), sortable: true, textAlign: 'text-center'},
            {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
          ],
          meta: [],
          endPoint: '',
          restaurantList: [],
          status_order: [
            {
              id: 1,
              name: this.$t("table_order.status_order.waiting"),
            },
            {
              id: 2,
              name: this.$t("table_order.status_order.confirmed"),
            },
            {
              id: 3,
              name: this.$t("table_order.status_order.cancelled"),
            },
          ]
        };
      },
      watch : {
        "filters.from_date_between.value" : async function(value) {
          await this.$refs.form_create_entry.validate()
        },
        "filters.to_date_between.value" : async function(value) {
          await this.$refs.form_create_entry.validate()
        },
      },
      methods: {
        handleBtnCreateClick() {
          this.$router.push({name: this.ROUTES.ADMIN.KYY_TABLE_ORDER_CREATE})
        },
        handleBtnUpdateClick(entry) {
          this.$router.push({name: this.ROUTES.ADMIN.KYY_TABLE_ORDER_EDIT, params: {id: entry.id}})
        },
        onResetAllSearch() {
          this.$router.push({name: this.$route.name,
            query: {
              'filters.infor_type.equal' : 'all',
              'filters.status_publishing.equal' : 'all'
            }
          })
        },
        onSearch() {
          // this.getTourSelectName();
        },
      },
      mounted: async function () {
        let response = await this.$request.get(this.ENDPOINT.KYY_RESTAURANT_LIST_SELECT);
        if (response.hasErrors()) {
          if (response.status === 'error'){
            this.__noticeError(this.$t(response.data.error_msg));
          }
        } else {
          this.restaurantList = response.data.data.map(item => {
            return {
              id: item.id,
              name: item.name
            }
          });
        }
      },
    }
  </script>
  